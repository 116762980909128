import styled from "styled-components";

export const TitleH2 = styled.h2`
  opacity: 1;
  letter-spacing: -1px;
  font-size: 46px;
  font-weight: 700;
  line-height: 56px;
  margin-bottom: 20px;
  &.font-Oswald{
    font-family: Oswald;
  }
  .value {
    svg {
      width: 100%;
    }
  }
  .good {
    svg {
      width: 100%;
    }
  }
  .svz {
    width: 100%;
  }
  .MagentoDesignLine {
    svg {
      width: 100%;
    }
  }
  @media screen and (min-width: 1440px) {
    letter-spacing: -1.5px;
    margin-bottom: 20px;
    margin-top: 0;
    font-size: 57px;
    line-height: 67px;
    font-weight: 700;
  }

  @media screen and (max-width: 1024px) {
    &.EngagementTitel {
      font-size: 42px;
      line-height: 52px;
    }
    &.ExploreServiceTitle {
      font-size: 39px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    &.MicroservicesTechnologies{
      font-size: 35px !important;
      line-height: 45px !important;
    }
  }
  
  @media screen and (max-width: 991px) {
    font-size: 33px;
    line-height: 45px;
    opacity: 1;
    letter-spacing: -1px;

    .BackEndSvg {
      top: -25px !important;
    }
    .Richfluid {
      display: none;
    }
    .fullsizesvg {
      right: -45px;
      bottom: -11px;
      svg {
        width: 70%;
      }
    }
    &.EngagementTitel {
      font-size: 33px;
      line-height: 43px;
    }
    &.ManufacturingServiceTitle {
      font-size: 32px;
      line-height: 42px;
    }
    &.ExploreServiceTitle {
      font-size: 30px;
      line-height: 40px;
    }
    &.MicroservicesTechnologies{
      font-size: 25px !important;
      line-height: 35px !important;
    }
  }
  @media screen and (max-width: 778px) {
    letter-spacing: -1px;
    width: 100%;
    font-weight: 700;
    margin-bottom: 24px;

    &.ManufacturingServiceTitle {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 767px) {
    br {
      display: none;
    }
  }

  @media screen and (max-width: 736px) {
    &.ExploreServiceTitle {
      font-size: 23px;
      line-height: 33px;
    }
  }
  @media screen and (max-width: 479px) {
    letter-spacing: -0.5px;
    font-size: 25px;
    line-height: 35px;
    .fullsizesvg {
      right: -25px;
      bottom: -20px;
      svg {
        width: 70%;
      }
    }
    br {
      display: none;
    }
    .BackEndSvg {
      top: -20px !important;
    }
    .partnerships {
      svg {
        width: 70%;
      }
    }
    .fullsizesvg {
      svg {
        width: 50%;
      }
    }
    &.EngagingTitle {
      font-size: 24px;
      line-height: 34px;
    }
    &.ManufacturingServiceTitle {
      font-size: 25px;
      line-height: 35px;
    }
    &.ExploreServiceTitle {
      font-size: 25px;
      line-height: 35px;
    }
  }

  @media (min-width: 390px) and (max-width: 415px) {
    .partnerships {
      right: -2rem;
    }
  }
  @media (min-width: 380px) and (max-width: 390px) {
    font-size: 23px;
    line-height: 32px;
    &.GenerateTitle {
      font-size: 25px;
      line-height: 35px;
    }
    &.EngagingTitle {
      font-size: 24px;
      line-height: 34px;
    }
  }
  @media (min-width: 350px) and (max-width: 379px) {
    font-size: 22px;
    line-height: 32px;
    &.EngagingTitle {
      font-size: 23px;
      line-height: 33px;
    }
    &.GenerateTitle {
      font-size: 24px;
      line-height: 34px;
    }
  }
`;

export const TitleMain = styled.div`
  text-align: center;
  max-width: 800px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media screen and (min-width: 1440px) {
    max-width: 1050px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 991px) {
    max-width: 700px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 778px) {
    max-width: 600px;
  }
  @media screen and (max-width: 479px) {
    max-width: 370px;
    margin-bottom: 30px;
  }
`;

export const InnerTabsButton = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding: 13px 25px;
  border-radius: 12px;
  background: #f8f8f8;
  color: #000;
  cursor: pointer;
  min-width: fit-content;

  &.active {
    background: #ff0080;
    color: #fff;
  }
  &:hover {
    background: #ff0080;
    color: #fff;
  }
  @media (max-width: 414px) {
    font-size: 12px;
  }
`;

export const TabInnerDiv = styled.div`
  position: relative;
  width: 150px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    transition: all 0.5s ease;
  }
  svg {
    transition: all 0.5s ease;
  }

  &:hover {
    p {
      font-weight: 600;
    }
    img {
      transform: scale(1.05);
      transition: all 0.5s ease;
    }
    svg {
      transform: scale(1.05);
      transition: all 0.5s ease;
    }
    &:after {
      content: "";
      position: absolute;
      width: 150px;
      height: 150px;
      top: -12px;
      left: 0;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 12px;
    }
  }

  @media (max-width: 600px) {
    /* width: 18%; */
    min-height: 130px;

    img {
      height: 50px;
      width: 50px;
    }
    p {
      font-size: 14px;
      text-align: center;
    }
    &:hover {
      &:after {
        display: none;
      }
      img {
        transform: scale(1);
        transition: all 0.5s ease;
      }
    }
  }
  @media (max-width: 475px) {
    width: 100px;
    min-height: 100px;

    &:hover {
      &:after {
        width: 100px;
        height: 100px;
      }
    }
  }
`;

export const TechnologiesButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 3rem 0;
  position: relative;

  @media (max-width: 1200px) {
    gap: 1rem;
    width: 1200px;
  }
  @media (max-width: 820px) {
    gap: 0.5rem;
  }
  @media (max-width: 778px) {
    padding: 1.5rem 1rem;
    gap: 1.5rem;
    overflow: scroll;
  }
  @media (max-width: 600px) {
    width: 1130px;
  }
  @media (max-width: 430px) {
    width: 1130px;
  }
  @media (max-width: 390px) {
    width: 1040px;
  }
`;

export const TechnologiesContant = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 950px;
  margin: 0 auto;
  gap: 2rem;
  justify-content: center;
  padding: 2rem 0;
  padding-bottom: 0;

  &.SveltsJsTecho {
    max-width: 1200px;
  }
  &.LogoTransformMain {
    max-width: 1240px;
  }
  @media (max-width: 920px) {
    gap: 2rem;
  }
  @media (max-width: 778px) {
    min-height: 440px;
    &.LogoTransformMain {
      min-height: auto;
    }
  }
  @media (max-width: 600px) {
    column-gap: 0rem;
    column-gap: 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-template-rows: max-content;
    &.ProgressiveWebTechnologies {
      column-gap: 4rem;
    }
    &.HirePaythoneTchnoMain {
      gap: 2rem;
      grid-template-columns: 1fr 1fr;
      column-gap: 0px;
    }
    &.SoftwareTestingTechnologiesMain {
      gap: 2rem;
      grid-template-columns: 1fr 1fr;
      column-gap: 0px;
    }
    &.LogoTransformMain {
      column-gap: 1.5rem;
      gap: 1.5rem;
    }
  }
`;

export const TechnologiesContantMain = styled.div`
  width: 100%;
  overflow: auto;
`;

export const TechnologiesTabsMain = styled.div`
  ${"" /* padding-top: 7rem; */}
  &.HomeTechnologiesTabsMain {
    padding-top: 7rem;
  }
  @media (max-width: 1024px) {
    ${
      "" /* padding-top: 1rem;
    padding-bottom: 4rem; */
    }
  }
  @media (max-width: 430px) {
    ${
      "" /* padding-top: 7rem;
    padding-bottom: 0rem; */
    }
  }
`;